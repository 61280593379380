import _d3Array from "d3-array";
import _d3Axis from "d3-axis";
import _d3Brush from "d3-brush";
import _d3Chord from "d3-chord";
import _d3Collection from "d3-collection";
import _d3Color from "d3-color";
import _d3Contour from "d3-contour";
import _d3Dispatch from "d3-dispatch";
import _d3Drag from "d3-drag";
import _d3Dsv from "d3-dsv";
import _d3Ease from "d3-ease";
import _d3Fetch from "d3-fetch";
import _d3Force from "d3-force";
import _d3Format from "d3-format";
import _d3Geo from "d3-geo";
import _d3Hierarchy from "d3-hierarchy";
import _d3Interpolate from "d3-interpolate";
import _d3Path from "d3-path";
import _d3Polygon from "d3-polygon";
import _d3Quadtree from "d3-quadtree";
import _d3Random from "d3-random";
import _d3Scale from "d3-scale";
import _d3ScaleChromatic from "d3-scale-chromatic";
import _d3Selection from "d3-selection";
import _d3Shape from "d3-shape";
import _d3Time from "d3-time";
import _d3TimeFormat from "d3-time-format";
import _d3Timer from "d3-timer";
import _d3Transition from "d3-transition";
import _d3Voronoi from "d3-voronoi";
import _d3Zoom from "d3-zoom";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var d3Array = _d3Array;
var d3Axis = _d3Axis;
var d3Brush = _d3Brush;
var d3Chord = _d3Chord;
var d3Collection = _d3Collection;
var d3Color = _d3Color;
var d3Contour = _d3Contour;
var d3Dispatch = _d3Dispatch;
var d3Drag = _d3Drag;
var d3Dsv = _d3Dsv;
var d3Ease = _d3Ease;
var d3Fetch = _d3Fetch;
var d3Force = _d3Force;
var d3Format = _d3Format;
var d3Geo = _d3Geo;
var d3Hierarchy = _d3Hierarchy;
var d3Interpolate = _d3Interpolate;
var d3Path = _d3Path;
var d3Polygon = _d3Polygon;
var d3Quadtree = _d3Quadtree;
var d3Random = _d3Random;
var d3Scale = _d3Scale;
var d3ScaleChromatic = _d3ScaleChromatic;
var d3Selection = _d3Selection;
var d3Shape = _d3Shape;
var d3Time = _d3Time;
var d3TimeFormat = _d3TimeFormat;
var d3Timer = _d3Timer;
var d3Transition = _d3Transition;
var d3Voronoi = _d3Voronoi;
var d3Zoom = _d3Zoom;
var version = "5.16.0";
Object.keys(d3Array).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Array[k];
    }
  });
});
Object.keys(d3Axis).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Axis[k];
    }
  });
});
Object.keys(d3Brush).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Brush[k];
    }
  });
});
Object.keys(d3Chord).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Chord[k];
    }
  });
});
Object.keys(d3Collection).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Collection[k];
    }
  });
});
Object.keys(d3Color).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Color[k];
    }
  });
});
Object.keys(d3Contour).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Contour[k];
    }
  });
});
Object.keys(d3Dispatch).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Dispatch[k];
    }
  });
});
Object.keys(d3Drag).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Drag[k];
    }
  });
});
Object.keys(d3Dsv).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Dsv[k];
    }
  });
});
Object.keys(d3Ease).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Ease[k];
    }
  });
});
Object.keys(d3Fetch).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Fetch[k];
    }
  });
});
Object.keys(d3Force).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Force[k];
    }
  });
});
Object.keys(d3Format).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Format[k];
    }
  });
});
Object.keys(d3Geo).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Geo[k];
    }
  });
});
Object.keys(d3Hierarchy).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Hierarchy[k];
    }
  });
});
Object.keys(d3Interpolate).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Interpolate[k];
    }
  });
});
Object.keys(d3Path).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Path[k];
    }
  });
});
Object.keys(d3Polygon).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Polygon[k];
    }
  });
});
Object.keys(d3Quadtree).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Quadtree[k];
    }
  });
});
Object.keys(d3Random).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Random[k];
    }
  });
});
Object.keys(d3Scale).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Scale[k];
    }
  });
});
Object.keys(d3ScaleChromatic).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3ScaleChromatic[k];
    }
  });
});
Object.keys(d3Selection).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Selection[k];
    }
  });
});
Object.keys(d3Shape).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Shape[k];
    }
  });
});
Object.keys(d3Time).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Time[k];
    }
  });
});
Object.keys(d3TimeFormat).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3TimeFormat[k];
    }
  });
});
Object.keys(d3Timer).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Timer[k];
    }
  });
});
Object.keys(d3Transition).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Transition[k];
    }
  });
});
Object.keys(d3Voronoi).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Voronoi[k];
    }
  });
});
Object.keys(d3Zoom).forEach(function (k) {
  if (k !== "default") Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () {
      return d3Zoom[k];
    }
  });
});
exports.version = version;
export default exports;
export const __esModule = exports.__esModule;
const _version = exports.version;
export { _version as version };